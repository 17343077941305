var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "timeline" },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "b-button",
        {
          key: index,
          staticClass: "timeline-item",
          attrs: { id: "button-" + index, variant: "primary" },
          on: {
            click: function ($event) {
              return _vm.navigateToItem(item)
            },
          },
        },
        [
          _c("span", { staticClass: "button-text" }, [
            _vm._v(_vm._s(item.name)),
          ]),
          !item.monitored
            ? _c("i", { staticClass: "icon not-monitored" }, [_vm._v("❌")])
            : _c("i", { staticClass: "icon monitored" }, [_vm._v("✅")]),
          _c(
            "b-tooltip",
            {
              attrs: {
                target: "button-" + index,
                title: _vm.tooltipText(item),
                triggers: "hover",
              },
            },
            [
              [
                !item.monitored
                  ? _c("span", { staticClass: "tooltip-text" }, [
                      _vm._v(
                        "No " + _vm._s(item.name.toLowerCase()) + " monitored"
                      ),
                    ])
                  : _c("span", { staticClass: "tooltip-text" }, [
                      _vm._v(
                        _vm._s(item.name.toLowerCase()) + " being monitored"
                      ),
                    ]),
              ],
            ],
            2
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }