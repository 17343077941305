var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      !_vm.loaded
        ? _c("ct-centered-spinner", { attrs: { height: "16.25rem" } })
        : _vm._e(),
      _vm.monitoringServiceActive && _vm.loaded
        ? _c(
            "div",
            { staticClass: "overview-card" },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      attrs: { "header-tag": "header", role: "tab" },
                      on: { click: _vm.toggleCollapse },
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass:
                            "mb-0 d-flex justify-content-between align-items-center",
                        },
                        [
                          _vm._v("\n          Overview\n          "),
                          _c("b-icon", {
                            attrs: {
                              icon: _vm.isCollapsed
                                ? "chevron-down"
                                : "chevron-up",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "overview-collapse",
                        accordion: "overview",
                        role: "tabpanel",
                      },
                      model: {
                        value: _vm.isCollapsed,
                        callback: function ($$v) {
                          _vm.isCollapsed = $$v
                        },
                        expression: "isCollapsed",
                      },
                    },
                    [
                      _c("b-card-body", [
                        _c("p", [
                          _vm._v(
                            "\n            Trademark monitoring continuously scours the internet for potential infringement, \n            and sends you a detailed report each month.\n          "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n            Setting up your monitoring service is simple—here's how it works:\n          "
                          ),
                        ]),
                        _c("ol", [
                          _c(
                            "li",
                            [
                              _c("strong", [
                                _vm._v('Add "Identities" to monitor:'),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n                Think of an Identity as a way to keep tabs on possible ways your trademark may appear. Set up each\n                Identity, and we'll monitor it.\n              "
                                ),
                              ]),
                              _c("monitoring-overview-timeline", {
                                attrs: {
                                  items: _vm.timelineItems,
                                  account: _vm.account,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("li", [
                            _c("strong", [
                              _vm._v("Review your monthly reports:"),
                            ]),
                            _c(
                              "p",
                              [
                                _vm._v(
                                  "\n                Once your preferred Identities are set up, we'll send your monthly report to your client account\n                once\n                a month, viewable in the "
                                ),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: { name: "monitoring-reports" },
                                    },
                                  },
                                  [_vm._v("Reports")]
                                ),
                                _vm._v(
                                  "\n                tab. This report identifies and details potential trademark\n                conflicts or threats, such as companies or domains using a similar name that could confuse your\n                customers and harm your business.\n              "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "overview-card" }, [
                _c("h3", { staticClass: "header" }, [
                  _vm._v("Identities in your next report"),
                ]),
                _vm.identities.length
                  ? _c(
                      "div",
                      [
                        _c("b-table", {
                          staticClass: "table",
                          attrs: {
                            hover: "",
                            items: _vm.identities,
                            fields: _vm.fields,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "head(value)",
                                fn: function () {
                                  return [_vm._v("Identity")]
                                },
                                proxy: true,
                              },
                              {
                                key: "cell(value)",
                                fn: function (data) {
                                  return [_vm._v(_vm._s(data.item.value))]
                                },
                              },
                              {
                                key: "head(type)",
                                fn: function () {
                                  return [_vm._v("Type")]
                                },
                                proxy: true,
                              },
                              {
                                key: "cell(type)",
                                fn: function (data) {
                                  return [
                                    _vm._v(
                                      _vm._s(_vm.identityType[data.item.type])
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "head(actions)",
                                fn: function () {
                                  return [_vm._v("Actions")]
                                },
                                proxy: true,
                              },
                              {
                                key: "cell(actions)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          "aria-label": "Manage button",
                                          variant: "primary",
                                          size: "sm",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.navigateToEdit(data.item)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Manage\n            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "delete-button",
                                        attrs: {
                                          "aria-label": "Manage button",
                                          variant: "danger",
                                          size: "sm",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectDeleteItem(
                                              data.item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Remove\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3097838801
                          ),
                        }),
                        _c("div", [
                          _vm._v(
                            "\n          Monitoring " +
                              _vm._s(_vm.identities.length) +
                              " out of " +
                              _vm._s(_vm.monitoringIdentityMaxAllowed) +
                              " allotted Identities.\n        "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _c("div", { attrs: { id: "no-monitoring" } }, [
                      _vm._v(
                        "\n        You aren't currently monitoring any Identities.\n        Select one of the Identity options above to get started!\n      "
                      ),
                    ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-footer": "",
            title: "Are you sure you want to delete?",
          },
          model: {
            value: _vm.confirmDelete,
            callback: function ($$v) {
              _vm.confirmDelete = $$v
            },
            expression: "confirmDelete",
          },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: {
                variant: "secondary",
                block: "",
                "aria-label": "Cancel button",
              },
              on: { click: _vm.closeDeleteModal },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: {
                variant: "danger",
                block: "",
                "aria-label": "Confirm button",
              },
              on: { click: _vm.deleteItem },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }