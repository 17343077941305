<template>
  <div class="timeline">
    <b-button v-for="(item, index) in items" :id="'button-' + index" :key="index" variant="primary" class="timeline-item" @click="navigateToItem(item)">
      <span class="button-text">{{ item.name }}</span>
      <i v-if="!item.monitored" class="icon not-monitored">❌</i>
      <i v-else class="icon monitored">✅</i>
      <b-tooltip :target="'button-' + index" :title="tooltipText(item)" triggers="hover">
        <template>
          <span v-if="!item.monitored" class="tooltip-text">No {{ item.name.toLowerCase() }} monitored</span>
          <span v-else class="tooltip-text">{{ item.name.toLowerCase() }} being monitored</span>
        </template>
      </b-tooltip>
    </b-button>
  </div>
</template>

<script>
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    account: { type: Object, required: true },
  },
  computed: {
    isAdmin() {
      return sessionStorage.getItem('admin-logged-in') === 'true'
    },
  },
  methods: {
    async navigateToItem(item) {
      await this.logMonitoringDashboardClick(this.account, item.name) 
      this.$router.push({ name: item.route })
    },
    tooltipText(item) {
      return item.monitored ? `${item.name} being monitored` : `No ${item.name} monitored`
    },
    async logMonitoringDashboardClick(account, tab) {
      if (this.isAdmin) 
        return

      await createOrFindClientInteractionLog({
        category: 'monitoring',
        subCategory: 'clicked-timeline',
        interaction: {
          type: 'button',
          action: 'redirect',
          name: `clicked-${tab.toLowerCase()}-timeline`,
        },
        accountId: account.id,
      })
    },
  },
}
</script>

<style scoped>
.timeline {
  padding: 0;
  width: 95%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.timeline-item {
  position: relative;
  width: 12rem;
}

.button-text {
  display: inline-block;
}

.icon {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-size: 1rem;
}

.not-monitored {
  color: red;
}

.monitored {
  color: green;
}

.tooltip-text {
  font-size: 0.8rem;
}
</style>
